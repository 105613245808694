<template>
    <v-card flat class="pa-3 mt-2">
      <v-card-subtitle> Categoria Fiscal </v-card-subtitle>
      <v-form ref="form" lazy-validation>
        <v-card-text class="d-flex">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12"  >
                  <v-text-field
                    :rules="reglas.requerido"
                    v-model="datos.descripcion"
                    label="Nombre"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
             
                    </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
              <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </template>
  <script>
  import { onBeforeMount, ref } from '@vue/composition-api'
  import store from '@/store'
  import goTo from 'vuetify/lib/services/goto'
import CategoriaServices from '@/api/servicios/inventario/maestro/CategoriaServices'
  export default {
    setup(props, context) {
      const guardando = ref(false)
      const form = ref(null)
      const reglas = {
        requerido: [v => !!v || 'Es requerido'],
        
      }
      const datosInit = {
        id: -1,  
        descripcion: '',
         
      }
      const datos = ref(JSON.parse(JSON.stringify({...datosInit})))
  
      const limpiar = () => {
        datos.value = JSON.parse(JSON.stringify({...datosInit}))
        form.value.resetValidation()
      }
  
      const cargandos = ref(false)
  
      const CargarEditar = item => {
        datos.value = item
        goTo(0)
      }
      const validarForm = () => {
        let val = form.value?.validate()
  
        return val
      }
      const ActualizarLista = item => {
        console.log(item)
        context.emit('GetActualizarLista', { ...item })
      }
      const Guardar = () => {
        guardando.value = true
        if (validarForm()) {
          try {
            CategoriaServices.categoriaFiscalActualizar({ ...datos.value })
              .then(response => {
                console.log(response)
                if (response.data.mensaje == "GUARDADO CON EXITO") {
                  store.commit('setAlert', {
                    message: response.data.mensaje,
                    type: 'success',
                  })
                  ActualizarLista(response.data.datos)
                  limpiar()
                } else {
                  store.commit('setAlert', {
                    message: `${response.data.mensaje}: `,
                    type: 'warning',
                  })
                }
              })
              .catch(error => {
                console.log(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'Guardar',
                  
                })
              })
              .finally(() => {
                guardando.value = false
              })
          } catch (error) { 
            store.commit('setAlert', {
              message: error,
              type: 'error',
                  error: error,
                  funcion: 'Guardar',
            })
          }
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      }
  
      return {
        reglas,
        datos,
        CargarEditar,
        cargandos,
        limpiar,
        Guardar,
        form,
        ActualizarLista,
      }
    },
  }
  </script>