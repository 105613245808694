 
<template>
  <span>
    <v-dialog v-model="dialog" max-width="600px" scrollable persistent>
      <v-card>
        <v-card-subtitle class="text-center justify-center py-6"> {{ producto.nombre }} </v-card-subtitle>

        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card style="max-height: 400px; overflow-y: scroll">
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <p class="mb-0"><strong>Nombre:</strong></p>
                    <p>{{ producto.nombre }}</p>
                    <p class="mb-0"><strong>Referencia:</strong></p>
                    <p class="mb-0">{{ producto.referencia }}</p>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-avatar size="100">
                      <v-img
                        :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                        :src="`${config.api_imagenes}/producto/img/s-${producto.img}`"
                        alt="John"
                      />
                    </v-avatar>
                  </v-col>

                  <v-col cols="12">
                    <p class="mb-0"><strong>Descripcion:</strong></p>
                    <p class="mb-0">{{ producto.descripcion }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>costo:</strong></p>
                    <p class="mb-0">{{ producto.costo }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>precio_venta:</strong></p>
                    <p class="mb-0">{{ producto.precio_venta }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>utilidad:</strong></p>
                    <p class="mb-0">{{ producto.utilidad }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>und_caja:</strong></p>
                    <p class="mb-0">{{ producto.und_caja }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>dias_vencimiento_produccion:</strong></p>
                    <p class="mb-0">{{ producto.dias_vencimiento_produccion }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Producto tipo:</strong></p>
                    <p class="mb-0">{{ producto.producto_tipo.descripcion }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Categoria:</strong></p>
                    <p class="mb-0">{{ producto.categoria.nombre }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Sub categoria:</strong></p>
                    <p class="mb-0">{{ producto.sub_categoria.nombre }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Unidad medida:</strong></p>
                    <p class="mb-0">{{ producto.unidad_medida.descripcion }}</p>
                  </v-col>

                  <v-col cols="6">
                    <p class="mb-0"><strong>Impuesto tasa:</strong></p>
                    <p class="mb-0">{{ producto.impuesto_tasa.descripcion }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Categoria fiscal:</strong></p>
                    <p class="mb-0">{{ producto.categoria_fiscal.descripcion }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Estado:</strong></p>
                    <p class="mb-0">{{ producto.estado_json.descripcion }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0"><strong>cuenta_contable:</strong></p>
                    <p class="mb-0">{{ producto.cuenta_contable.nombre }}</p>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo Servicio"
                      :color="producto.ind_servicio ? 'success' : 'error'"
                      v-model="producto.ind_servicio"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo Compra"
                      :color="producto.ind_compra ? 'success' : 'error'"
                      v-model="producto.ind_compra"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo Responsable"
                      :color="producto.ind_responsable ? 'success' : 'error'"
                      v-model="producto.ind_responsable"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo Procesar Comanda"
                      :color="producto.ind_imprimir_auto ? 'success' : 'error'"
                      v-model="producto.ind_imprimir_auto"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo Maneja Inventario"
                      :color="producto.ind_inventario ? 'success' : 'error'"
                      v-model="producto.ind_inventario"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo llevar a costo"
                      :color="producto.ind_llevar_costo ? 'success' : 'error'"
                      v-model="producto.ind_llevar_costo"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      readonly
                      label="Activo peso conteo"
                      :color="producto.ind_peso_conteo ? 'success' : 'error'"
                      v-model="producto.ind_peso_conteo"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-chip
                      class="primary"
                      x-small
                      v-for="pues in producto.puestos.filter(item => item.ind_activo)"
                      :key="pues.id"
                      >{{ pues.nombre }}</v-chip
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card style="max-height: 400px; overflow-y: scroll">
              <v-card-text v-if="generales_compra.min_fecha">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Costo Minimo</td>
                        <td class="text-right">
                          {{ funciones.formatoNumeric(generales_compra.min_costo_unidad, 4) }} $
                        </td>
                      </tr>
                      <tr>
                        <td>Costo Maximo</td>
                        <td class="text-right">
                          {{ funciones.formatoNumeric(generales_compra.max_costo_unidad, 4) }} $
                        </td>
                      </tr>
                      <tr>
                        <td>Costo Promedio</td>
                        <td class="text-right">
                          {{ funciones.formatoNumeric(generales_compra.avg_costo_unidad, 4) }} $
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                      </tr>
                      <tr>
                        <td>Cantidad Maxima</td>
                        <td class="text-right">{{ funciones.formatoNumeric(generales_compra.min_cantidad, 2) }} U</td>
                      </tr>
                      <tr>
                        <td>Cantidad Minima</td>
                        <td class="text-right">{{ funciones.formatoNumeric(generales_compra.min_cantidad, 2) }} U</td>
                      </tr>
                      <tr>
                        <td>Cantidad Promedio</td>
                        <td class="text-right">{{ funciones.formatoNumeric(generales_compra.max_cantidad, 2) }} U</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                      </tr>
                      <tr>
                        <td>Primera Compra</td>
                        <td class="text-right">{{ funciones.formatoFecha(generales_compra.min_fecha, 1) }}</td>
                      </tr>
                      <tr>
                        <td>Ultima Compra</td>
                        <td class="text-right">{{ funciones.formatoFecha(generales_compra.max_fecha) }}</td>
                      </tr>
                      <tr>
                        <td>Ultima Proveedor</td>
                        <td class="text-right">{{ producto.ult_compra.nombre }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-divider></v-divider>
                <h3 class="mt-4 text-center">Ulimas compras</h3>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Numero</th>
                        <th class="text-left">Proveedor</th>
                        <th class="text-right">Cantidad</th>
                        <th class="text-right">Costo</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in compras" :key="index">
                        <td>
                          <small>{{ item.numero_documento }}</small>
                          <br />
                          <small>{{ item.fecha_factura }}</small>
                        </td>
                        <td>
                          <small>{{ item.nombre }}</small>
                        </td>
                        <td class="text-right">{{ funciones.formatoNumeric(item.cantidad, 2) }}</td>
                        <td class="text-right">{{ funciones.formatoNumeric(item.costo_unidad, 6) }}</td>
                        <td>
                          <v-btn small icon @click="GetVer(item)">
                            <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-text v-else  class="text-center">
                No se ha generado alguna compra
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card style="max-height: 400px; overflow-y: scroll">
              <v-card-text v-if="generales_ventas.min_fecha">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Precio Minimo</td>
                        <td class="text-right">{{ generales_ventas.min_precio.toFixed(4) }} $</td>
                      </tr>
                      <tr>
                        <td>Precio Maximo</td>
                        <td class="text-right">{{ generales_ventas.max_precio.toFixed(4) }} $</td>
                      </tr>
                      <tr>
                        <td>Precio Promedio</td>
                        <td class="text-right">{{ generales_ventas.avg_precio.toFixed(4) }} $</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                      </tr>
                      <tr>
                        <td>Cantidad Maxima</td>
                        <td class="text-right">{{ generales_ventas.min_cant.toFixed(2) }} U</td>
                      </tr>
                      <tr>
                        <td>Cantidad Minima</td>
                        <td class="text-right">{{ generales_ventas.min_cant.toFixed(2) }} U</td>
                      </tr>
                      <tr>
                        <td>Cantidad Promedio</td>
                        <td class="text-right">{{ generales_ventas.max_cant.toFixed(2) }} U</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                      </tr>
                      <tr>
                        <td>Primera Venta</td>
                        <td class="text-right">{{ funciones.formatoFecha(generales_ventas.min_fecha, 1) }}</td>
                      </tr>
                      <tr>
                        <td>Ultima Venta</td>
                        <td class="text-right">{{ funciones.formatoFecha(generales_ventas.max_fecha) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-divider></v-divider>
                <h3 class="mt-4 text-center">Ulimas ventas</h3>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Numero</th>
                        <th class="text-left">Cliente</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">Precio</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in ventas" :key="index">
                        <td>
                          <small>{{ item.id }}</small> <br />
                          <small>{{ item.fecha }}</small>
                        </td>
                        <td>
                          <small>{{ item.cliente }}</small>
                        </td>
                        <td>{{ funciones.formatoNumeric(item.cant, 2) }}</td>
                        <td>{{ funciones.formatoNumeric(item.precio, 2) }}</td>
                        <td>
                          <v-btn small icon @click="GetVer(item)">
                            <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-text v-else class="text-center">
                No se ha generado alguna venta
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card style="max-height: 400px; overflow-y: scroll">
              <v-card-text v-if="logs.datos">
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(item, index) in logs.datos"
                    :key="index"
                    :color="colorAccion(item.id_accion)"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <small>{{ funciones.formatoFecha(item.fecha_hora, 1) }}</small> <br />
                        <strong>{{ funciones.formatoFecha(item.fecha_hora, 2) }}</strong> <br /><small
                          >{{ item.descripcion }}
                        </small>
                      </v-col>
                      <v-col>
                        <strong>{{ item.empleado }}</strong> <br />
                      </v-col>
                      <v-col cols="auto">
                        <v-btn class="primary" x-small @click="abrirLogDetalle(item.datos)">Ver Cambios</v-btn></v-col
                      >
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
                <div class="text-center">
                  <v-btn
                    class="primary"
                    @click="logProductoPaginado(logs.paginado.pagina + 1)"
                    :disabled="!(logs.paginado.paginas - 1 > logs.paginado.pagina)"
                    >VER MAS</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLog" max-width="600px" scrollable persistent>
      <v-card>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Campo</th>
                  <th class="text-left">Anterior</th>
                  <th class="text-left">Nuevo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in logsDetalle" :key="index">
                  <td>
                    <small>{{ item.campo.toUpperCase() }}</small> <br />
                  </td>
                  <td>
                    <small>{{ item.anterior }}</small>
                  </td>
                  <td>
                    <small>{{ item.nuevo }}</small>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogLog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalViewCompra :VerButton="false" ref="ModalViewCompraRef"></ModalViewCompra>
  </span>
</template>

<script>
import { ref } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import ModalViewCompra from '@/views/sistema/puesto/compra/factura/componentes/ModalViewCompra.vue'
import { mdiEye } from '@mdi/js'
import config from '@/api/config'
import MaestroService from '@/api/servicios/MaestroService'
import SimpleTable from '../../../../simple-table/SimpleTable.vue'
export default {
  components: {
    ModalViewCompra,
  },
  setup() {
    SimpleTable
    const logoI = process.env.VUE_APP_IMG_LOGO
    const dialog = ref(false)
    const dialogLog = ref(false)
    const generales_compra = ref({
      min_costo_unidad: 0,
      max_costo_unidad: 0,
      avg_costo_unidad: 0,
      min_cantidad: 0,
      max_cantidad: 0,
      avg_cantidad: 0,
      min_fecha: '1900-01-01',
      max_fecha: '1900-01-01',
    })
    const logs = ref({})
    const logsDetalle = ref([])
    const compras = ref([])
    const tab = ref(0)
    const series = [
      {
        name: 'Desktops',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ]
    const chartOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      },
    }

    const colorAccion = accion => {
      switch (accion) {
        case 1:
          return 'success'
          break
        case 2:
          return 'info'
          break
        case 3:
          return 'error'
          break
        case 4:
          return 'warning'
          break
        case 5:
          return 'warning'
          break
        case 6:
          return 'error'
          break
        default:
          return 'info'
          break
      }
    }

    const abrirLogDetalle = datos => {
      let _dato = []
      if (Array.isArray(datos)) {
        _dato = datos
      } else {
        

        let claves = Object.keys(datos); // claves = ["nombre", "color", "macho", "edad"]
        for(let i=0; i< claves.length; i++){
          let clave = claves[i]; 
          _dato.push({ campo: clave, anterior: '', nuevo: datos[clave] })
        }

       
      }

      logsDetalle.value = _dato
      dialogLog.value = true
    }

    const logProductoPaginado = (pagina = 0, limit = 5) => {
      MaestroService.logProductoPaginado({ limit: limit, pagina: pagina, id_producto: producto.value.id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            if (pagina == 0) {
              logs.value = response.data.datos
            } else {
              console.log(logs.value.datos)
              console.log('response.data.datos.datos', response.data.datos.datos)
              logs.value.datos = logs.value.datos.concat(response.data.datos.datos)
              logs.value.paginado = response.data.datos.paginado
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: response.data.mensaje,
            type: 'Error logProductoPaginado:' + error,
          })
          console.log('Error logProductoPaginado:', error)
        })
        .finally(() => {})
    }

    const reporteDatosUltimasComprasProductos = () => {
      MaestroService.reporteDatosUltimasComprasProductos({ id_producto: producto.value.id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            compras.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: response.data.mensaje,
            type: 'Error reporteDatosUltimasComprasProductos:' + error,
          })
          console.log('Error reporteDatosUltimasComprasProductos:', error)
        })
        .finally(() => {})
    }

    const reporteDatosComprasProductosAgrupado = () => {
      MaestroService.reporteDatosComprasProductosAgrupado({ id_producto: producto.value.id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            generales_compra.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: response.data.mensaje,
            type: 'Error reporteDatosUltimasComprasProductos:' + error,
          })
          console.log('Error reporteDatosUltimasComprasProductos:', error)
        })
        .finally(() => {})
    }
    const generales_ventas = ref({
      max_precio: 0,
      min_precio: 0,
      avg_precio: 0,
      max_cant: 0,
      min_cant: 0,
      avg_cant: 0,
      min_fecha: '1900-01-01',
      max_fecha: '1900-01-01',
      cantidad_total: 0,
      venta_total: 0,
    })
    const reportedatosventasproductosagrupado = () => {
      MaestroService.reportedatosventasproductosagrupado({ id_producto: producto.value.id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            generales_ventas.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: response.data.mensaje,
            type: 'Error reportedatosventasproductosagrupado:' + error,
          })
          console.log('Error reportedatosventasproductosagrupado:', error)
        })
        .finally(() => {})
    }
    const ventas = ref([])
    const reportedatosultimasventasproductos = () => {
      MaestroService.reportedatosultimasventasproductos({ id_producto: producto.value.id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            ventas.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: response.data.mensaje,
            type: 'Error reportedatosultimasventasproductos:' + error,
          })
          console.log('Error reportedatosultimasventasproductos:', error)
        })
        .finally(() => {})
    }

    const producto = ref({})

    const abrir = _producto => {
      dialog.value = true
      producto.value = _producto
      tab.value = 0
      reporteDatosUltimasComprasProductos()
      reporteDatosComprasProductosAgrupado()
      reportedatosventasproductosagrupado()
      reportedatosultimasventasproductos()
      logProductoPaginado()
    }

    const ModalViewCompraRef = ref(null)
    const GetVer = item => {
      console.log(item)
       
      ModalViewCompraRef.value.abrir(item.id)
    }

    return {
      dialog,
      tab,
      items: ['Ficha', 'Compras', 'Ventas', 'Log'],
      compras,
      generales_compra,
      funciones,
      GetVer,
      ModalViewCompraRef,
      icons: {
        mdiEye,
      },
      series,
      chartOptions,
      abrir,
      producto,
      config,
      logoI,
      generales_ventas,
      ventas,
      colorAccion,
      logs,
      logProductoPaginado,
      logsDetalle,
      dialogLog,
      abrirLogDetalle,
    }
  },
}
</script>