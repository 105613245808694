
<template>
  <div>
    <v-card>
      <v-card-subtitle>Listar</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="filtro.valor"
              label="Nombre/descripcion/ref"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectProductoTipo
              :excluir="[3]"
              v-model="filtro.id_producto_tipo"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <select-puesto
              v-model="filtro.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"
              :storePuesto="true"
            ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectEstado
              v-model="filtro.estado"
              :padre="2"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectUnidadMedida
              v-model="filtro.id_unidad_medida"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectCategoria
              v-model="filtro.id_categoria"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectSubCategoria
              v-model="filtro.id_sub_categoria"
              :padre="filtro.id_categoria"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectCategoriaFiscal
              v-model="filtro.id_categoria_fiscal"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-btn
                  v-if="Lista && Lista.length > 0"
                  class="primary"
                  @click="exportExcel()"
                  :loading="cargandoDatos"
                  x-small
                  >exportar excel</v-btn
                >
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn class="primary" @click="cargarInicio()" :loading="cargandoDatos" small>Buscar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Accion</th>
                <th class="text-left">ID</th>
                <th class="text-left">Nombre</th>
                <th class="text-left">Tipo</th>
                <th class="text-left">Medida</th>
                <th class="text-left">Categoria</th>
                <th class="text-right">Costo</th>
                <th class="text-right">Utilidad</th>
                <th class="text-right">PVP</th>
                <th class="text-left">Estado</th>
                <th class="text-left">Receta</th>
                <th class="text-left">Puestos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Lista" :key="item.id">
                <td>
                  <v-btn icon @click="GetEditar(item)">
                    <v-icon color="primary">{{ icons.mdiPencil }}</v-icon></v-btn
                  >
                  <v-btn icon @click="GetInfo(item)">
                    <v-icon color="primary">{{ icons.mdiMagnify }}</v-icon></v-btn
                  >
                </td>
                <td class="text-center">
                  <small>{{ item.id }}</small
                  ><br />
                  <v-avatar size="40">
                    <v-img
                      :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                      :src="`${config.api_imagenes}/producto/img/s-${item.img}`"
                      alt="John"
                    />
                  </v-avatar>
                </td>

                <td>
                  {{ item.nombre }} <br />
                  <small>
                    <strong>{{ item.referencia }}</strong>
                  </small>
                </td>
                <td>{{ item.producto_tipo.descripcion }}</td>
                <td>{{ item.unidad_medida.descripcion }}</td>
                <td>
                  <small>{{ item.categoria.nombre }}</small>
                  <br />
                  <small>{{ item.sub_categoria.nombre }}</small>
                  <br />
                  <small v-if="item.producto_tipo.id == 1">FISCAL: {{ item.categoria_fiscal.descripcion }}</small>
                </td>
                <td class="text-right">{{ funciones.formatoNumeric(item.costo) }}</td>
                <td class="text-right">{{ funciones.formatoNumeric(item.utilidad) }}</td>
                <td class="text-right">
                  {{ funciones.formatoNumeric(item.precio_venta) }}
                  <small
                    >iva:{{ funciones.formatoNumeric(item.precio_venta * (1 + item.impuesto_tasa.valor / 100)) }}</small
                  >
                </td>
                <td>{{ item.estado_json.descripcion }}</td>
                <td>
                  <small v-if="item.receta.id">{{ item.receta.tipo_receta_json.descripcion }}</small>
                  <small v-else>Sin receta</small>
                </td>
                <td>
                  <v-chip
                    class="primary"
                    x-small
                    v-for="pues in item.puestos.filter(item => item.ind_activo)"
                    :key="pues.id"
                    >{{ pues.nombre }}</v-chip
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-right">
          <span class="pt-4 mr-2">Total Registros: {{ TotalRow }}</span>
          <br />
          <v-pagination :disabled="cargandoDatos" v-model="Pagina" :length="Paginas" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>
      <!-- Table -->
    </v-card>

    <ModalProductoDetalle ref="ModalProductoDetalleRef" />
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiUpload,
  mdiDownload,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import ProductosService from '@/api/servicios/ProductosService'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectCategoriaFiscal from '../../maestro/categoria-fiscal/componentes/Select.vue'
import SelectSubCategoria from '../../maestro/sub-categoria/componentes/Select.vue'
import SelectUnidadMedida from '../../maestro/unidad-medida/componentes/Select.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import funciones from '@/funciones/funciones'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue'
import ModalProductoDetalle from './ModalProductoDetalle.vue'
export default {
  components: {
    SelectCategoria,
    SelectSubCategoria,
    SelectUnidadMedida,
    SelectEstado,
    SelectProductoTipo,
    SelectPuesto,
    SelectCategoriaFiscal,
    ModalProductoDetalle,
  },
  setup(props, context) {
    const logoI = process.env.VUE_APP_IMG_LOGO
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(10)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const ModalProductoDetalleRef = ref(false)
    const filtroInit = {
      valor: '',
      id_categoria: null,
      id_sub_categoria: null,
      id_categoria_fiscal: null,
      id_unidad_medida: null,
      id_producto_tipo: null,
      estado: null,
      id_puesto: '',
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })

    const GetInfo = dato => {
      ModalProductoDetalleRef.value.abrir(dato)
    }

    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 10
      cargar()
    }
    const cargar = () => {
      cargandoDatos.value = true
      try {
        ProductosService.productoListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
          id_puesto: filtro.value.id_puesto ? filtro.value.id_puesto?.join(',') : '',
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
                  error: error,
                  funcion: 'cargar',
        })
        cargandoDatos.value = false
      }
    }
    const exportExcel = columasSelect => {
      window.open(
        `${config.api_master}inventario/producto/productoListarPaginadoExcel?${funciones.recorrerObjeto({
          ...filtro.value,
          token: store.state.user.token,
        })}`,
      )
    }
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,

        mdiUpload,
        mdiDownload,
      },
      exportExcel,
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'Activo', value: 'indActivo' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      filtro,
      funciones,
      logoI,
      ModalProductoDetalleRef,
      GetInfo,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  